<template>
  <div id="app">
    <router-view></router-view>

    <!-- <Rabbit @message="message"></Rabbit> -->
  </div>
</template>
<script>
// import Rabbit from '@/components/rabbit'
export default {
  // components: {
  //   Rabbit
  // },
  data () {
    return {}
  },
  watch: {
    $route (to, from) {
      // console.log(to)
    }
  },
  mounted () {},
  methods: {
    // message (res) {
    //   console.log(res)
    // }
  }
}
</script>
<style lang="less">
  body,html{margin: 0px;}
  html, body, #app {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    //background: #f0f0f0;
    overflow: hidden;
  }
  body {
    font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, 'STHeiTi', sans-serif;
  }
  a {
    text-decoration: none;
  }
  * {
    outline: none;
  }
  li {
    list-style: none;
  }
  #app {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
</style>
