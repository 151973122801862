import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login/index.vue')
  },
  {
    path: '/',
    redirect: '/main'
  },
  {
    path: '/main',
    name: 'main',
    redirect: '/transfer',
    component: () => import('../views/main.vue'),
    children: [
      {
        path: '/transfer',
        name: 'transfer',
        meta: {
          title: '中转管理'
        },
        component: () => import('../views/transfer/index.vue')
      },
      {
        path: '/vendor',
        name: 'vendor',
        meta: {
          title: '厂商管理'
        },
        component: () => import('../views/vendor/index.vue')
      },
      {
        path: '/parameter',
        name: 'parameter',
        meta: {
          title: '参数管理'
        },
        component: () => import('../views/parameter/index.vue')
      },
      {
        path: '/alarmName',
        name: 'alarmName',
        meta: {
          title: '告警名称管理'
        },
        component: () => import('../views/alarmName/index.vue')
      },
      {
        path: '/type',
        name: 'type',
        meta: {
          title: '类型管理'
        },
        component: () => import('../views/type/index.vue')
      },
      {
        path: '/model',
        name: 'model',
        meta: {
          title: '型号管理'
        },
        component: () => import('../views/model/index.vue')
      },
      {
        path: '/dock',
        name: 'dock',
        meta: {
          title: '对接管理'
        },
        component: () => import('../views/dock/index.vue')
      },
      {
        path: '/account',
        name: 'account',
        meta: {
          title: '账户管理'
        },
        component: () => import('../views/account/index.vue')
      },
    ]
  }
]

const router = new VueRouter({
  mode: 'hash',
  scrollBehavior: () => ({ x: 0, y: 0 }),
  routes
})
const originalPush = VueRouter.prototype.push
// 修改原型对象中的push方法
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

export default router
